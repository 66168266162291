var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-picker", {
    staticClass: "custom-date-picker",
    attrs: {
      format: _vm.format,
      "value-type": _vm.valueType,
      "default-value": _vm.DATE_TODAY,
      clearable: false,
      value: _vm.localDate,
      disabled: _vm.disabled,
      "disabled-date": _vm.isAfterToday,
      "aria-placeholder": "Select a date",
      placeholder: "Select a date",
    },
    on: { change: _vm.updateDate },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }