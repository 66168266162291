import AlternativeCalculationSettings from '@/models/AlternativeCalculationSettings';
import AlternativeCalculationSettingsDefaultDate from '@/models/AlternativeCalculationSettingsDefaultDate';
import ApiHelper from './apiHelper';

export default class AltCalcsDataApi {
    static async getAltCalcData (): Promise<Array<AlternativeCalculationSettings>> {
        return (await ApiHelper.post('GetAlternativePerformanceSettings', {})).data as Promise<Array<AlternativeCalculationSettings>>;
    }

    static async getAltCalcDefaultDate (): Promise<AlternativeCalculationSettingsDefaultDate> {
        return (await ApiHelper.post('AllInvestmentInceptionDates', {})).data as Promise<AlternativeCalculationSettingsDefaultDate>;
    }

    static async saveAltCalcData (saveData: Array<AlternativeCalculationSettings>): Promise<Array<AlternativeCalculationSettings>> {
        return (await ApiHelper.post('UpdateAlternativePerformanceSettings',  { alternativePerformanceSettingsWithId: saveData })).data as Promise<Array<AlternativeCalculationSettings>>;
    }
}
