











































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import AlternativeCalculationSettings from '@/models/AlternativeCalculationSettings';
import dayjs from 'dayjs';
import { FORMAT_DATE_ISO_SHORT } from '@/constants';
import DatePickerForAltCalcs from '@/components/MonitorManager/DatePickerForAltCalcs.vue';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import AlternativeCalculationSettingsDefaultDate from '@/models/AlternativeCalculationSettingsDefaultDate';
import { getIconByEntityTypeId } from '@/utils/getIconByEntityTypeId';
import { getEntityNameByEntityTypeId } from '@/utils/getEntityNameByEntityTypeId';
import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';
import { formatToDisplayDateYYYY } from '@/utils/formatToDisplayDate';

@Component({
    components: {
        DatePickerForAltCalcs,
        BaseButton,
    },
})
export default class AlternativeCalculationSettingsTable extends Vue {
    @Prop({ type: Array, required: true }) managerIds!: number[];

    @Prop({ type: Array, required: true }) updatedSettings!: Array<AlternativeCalculationSettings>;

    @Prop({ type: Array, required: true }) defaultDateObjects!: Array<AlternativeCalculationSettingsDefaultDate>;

    formatToDisplayDate = formatToDisplayDateYYYY;

    // public dateEnabled: { [key: number]: boolean } = {};

    public globalDate: string | null = null;

    localSettings: Array<AlternativeCalculationSettings & { defaultDate?: string }> = [];

    created (): void {
        this.initializeLocalSettings();

        // this.localSettings.forEach(investment => {
        //     this.disableDateIfNoCalculations(investment.entityNameId);
        //     // this.enableDateIfCalculations(investment.entityNameId);
        //     if (investment.defaultDate && investment.moicIrrCalculableStartDate) {
        //         Vue.set(this.dateEnabled, investment.entityNameId, true);
        //     }
        // });

    }

    @Watch('updatedSettings', { immediate: true, deep: true })
    onUpdatedSettingsChange (): void {
        this.initializeLocalSettings();
    }

    // @Watch('localSettings',  { deep: true })
    // onLocalSettingsChange (): void {
    //     this.localSettings.forEach(investment => {
    //         this.disableDateIfNoCalculations(investment.entityNameId);
    //         // this.enableDateIfCalculations(investment.entityNameId);
    //         if (investment.defaultDate && investment.moicIrrCalculableStartDate) {
    //             Vue.set(this.dateEnabled, investment.entityNameId, true);
    //         }
    //     });
    // }

    // @Watch('dateEnabled', { deep: true })
    // onDateEnabledChange (): void {
    //     this.localSettings.forEach(investment => {
    //         if (!this.dateEnabled[investment.entityNameId]) {
    //             this.updateIrrDateForManager(null, investment.entityNameId);
    //         }
    //     });
    // }

    get globalDateDisabledForInput (): boolean {
        return this.localSettings.every(investment => !investment.isInternalRateOfReturnCalculable && !investment.isMultipleOnInvestedCapitalCalculable);
    }

    removeAllDates (): void {
        this.localSettings.forEach(investment => {
            if (investment.isInternalRateOfReturnCalculable || investment.isMultipleOnInvestedCapitalCalculable) {
                this.updateIrrDateForManager(null, investment.entityNameId);
                // Vue.set(this.dateEnabled, investment.entityNameId, false);
            }
        });
    }

    initializeLocalSettings (): void {
        const dateMap: Record<string, string | null> = {};

        this.defaultDateObjects.forEach(dateObject => {
            Object.keys(dateObject).forEach(key => {
                const dateValue = dateObject[key];
                dateMap[key] = dateValue ? dayjs(dateValue).format(FORMAT_DATE_ISO_SHORT) : null;
            });
        });

        this.localSettings = this.updatedSettings.map(item => ({
            ...item,
            defaultDate: dateMap[item.entityNameId] || null
        }));
    }

    disabledTooltip (id: number, type = 'general'): string | null {
        if (type === 'general') {
            if (this.globalDateDisabledForInput) {
                return 'This option is disabled because both MOIC and MWRR are not calculable for all managers.';
            }
        } else if (type === 'each') {
            if (!this.getMoicForManager(id) && !this.getIrrForManager(id)) {
                return 'This option is disabled because both MOIC and MWRR are not calculable for this manager.';
            }
        }
        return null;
    }

    getMoicForManager (id: number): boolean {
        return this.localSettings.find(x => x.entityNameId === id)?.isMultipleOnInvestedCapitalCalculable || false;
    }

    getIrrForManager (id: number): boolean {
        return this.localSettings.find(x => x.entityNameId === id)?.isInternalRateOfReturnCalculable || false;
    }

    tickAllIrr (value: boolean): void {
        this.localSettings.forEach(investment => {
            if (!investment.defaultDate) return;
            this.updateIrrForManager(investment.entityNameId, value);
        });
    }

    tickAllMoic (value: boolean): void {
        this.localSettings.forEach(investment => {
            if (investment.moicIrrCalculableStartDate !== null) this.updateMoicForManager(investment.entityNameId, false);
            if (!investment.defaultDate || investment.moicIrrCalculableStartDate !== null) return;
            this.updateMoicForManager(investment.entityNameId, value);
        });
    }

    handleUpdateDate (date: string | null, investmentId: number): void {
        const investment = this.localSettings.find(x => x.entityNameId === investmentId);
        if (investment) {
            this.updateIrrDateForManager(date, investmentId);
            this.updateMoicForManager(investmentId, false);
        }
    }

    copyGlobalDate (): void {
        if (this.globalDate) {
            this.localSettings.forEach(investment => {
                if (investment.isInternalRateOfReturnCalculable || investment.isMultipleOnInvestedCapitalCalculable) {
                    this.updateIrrDateForManager(this.globalDate, investment.entityNameId);
                    // Vue.set(this.dateEnabled, investment.entityNameId, true);
                    this.updateMoicForManager(investment.entityNameId, false);
                }
            });
            this.globalDate = null;
        }
    }

    @Emit('updateMoicForManager')
    updateMoicForManager (id: number, value: boolean): [number, boolean] {
        const manager = this.localSettings.find(x => x.entityNameId === id);
        if (manager) {
            manager.isMultipleOnInvestedCapitalCalculable = value;
            // if (!manager.isMultipleOnInvestedCapitalCalculable && !manager.isInternalRateOfReturnCalculable) {
            //     this.updateIrrDateForManager(null, id);
            //     // this.disableDateIfNoCalculations(id);
            // } else {
            //     // this.enableDateIfCalculations(id);
            // }
        }
        return [id, value];
    }

    @Emit('updateIrrForManager')
    updateIrrForManager (id: number, value: boolean): [number, boolean] {
        const manager = this.localSettings.find(x => x.entityNameId === id);
        if (manager) {
            manager.isInternalRateOfReturnCalculable = value;
            if (!manager.isMultipleOnInvestedCapitalCalculable && !manager.isInternalRateOfReturnCalculable) {
                this.updateIrrDateForManager(null, id);
                // this.disableDateIfNoCalculations(id);
            } else {
                // this.enableDateIfCalculations(id);
            }
        }
        return [id, value];
    }

    @Emit('updateIrrDateForManager')
    updateIrrDateForManager (date: string | null, id: number): [string | null, number] {
        const manager = this.localSettings.find(x => x.entityNameId === id);
        if (manager) {
            Vue.set(manager, 'moicIrrCalculableStartDate', date);
        }
        return [date, id];
    }

    // disableDateIfNoCalculations (id: number): void {
    //     const manager = this.localSettings.find(x => x.entityNameId === id);
    //     if (manager && !manager.isInternalRateOfReturnCalculable && !manager.isMultipleOnInvestedCapitalCalculable) {
    //         // Vue.set(this.dateEnabled, id, false);
    //         this.updateIrrDateForManager(null, id);
    //     }
    // }

    // enableDateIfCalculations (id: number): void {
    //     const manager = this.localSettings.find(x => x.entityNameId === id);
    //     if (manager && (manager.isInternalRateOfReturnCalculable || manager.isMultipleOnInvestedCapitalCalculable)) {
    //         if (!this.dateEnabled[id]) {
    //             Vue.set(this.dateEnabled, id, false); // Ensure it is false but input is enabled
    //         }
    //     }
    // }


    getEntityNameByEntityTypeId (entityTypeId: TEntityTypeDto): string {
        return getEntityNameByEntityTypeId(entityTypeId);
    }

    getIconByEntityTypeId (entityTypeId: TEntityTypeDto): string {
        return getIconByEntityTypeId(entityTypeId);
    }
}
