


















































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import altCalcsDataApi from '@/api/altCalcsDataApi';
import dayjs from 'dayjs';
import ManagerList from '@/components/MonitorManager/ManagerList.vue';
import PerformanceTable from '@/components/MonitorManager/PerformanceTable.vue';
import BaseModal from '@/components/BaseModal.vue';
import UnSavedMessage from '@/components/MonitorManager/UnSavedMessage.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import cloneDeep from 'lodash.clonedeep';
import PartPeriodPerformance from '@/components/PartPeriodPerformance.vue';
import AlternativeCalculationSettings from '@/models/AlternativeCalculationSettings';
import AlternativeCalculationSettingsDefaultDate from '@/models/AlternativeCalculationSettingsDefaultDate';
import AlternativeCalculationSettingsTable from '@/components/MonitorManager/AlternativeCalculationSettingsTable.vue';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import { FORMAT_DATE_ISO_SHORT } from '@/constants';


@Component({
    components: {
        ManagerList,
        PerformanceTable,
        BaseModal,
        UnSavedMessage,
        LoadingOverlay,
        PartPeriodPerformance,
        AlternativeCalculationSettingsTable,
        BaseButton,
    },
})
export default class AlternativeCalculationsSettingsView extends Vue {

    @Ref('AlternativeCalculationSettingsTableRef') AlternativeCalculationSettingsTableRef!: AlternativeCalculationSettingsTable;

    public isPPPFetchingData = false;

    public isFetchingData = false;

    public isToBeSaveModal = false;

    public originalAltCalcData: Array<AlternativeCalculationSettings> = [];

    public updatedAltCalcData: Array<AlternativeCalculationSettings> = [];

    public defaultDateObjects: Array<AlternativeCalculationSettingsDefaultDate> = [];

    public showAltCalcData = true;

    public managerIds: number[] = [];

    public DATE_TODAY = dayjs().format(FORMAT_DATE_ISO_SHORT);

    public isAltCalcsFeatureLive: boolean = process.env.VUE_APP_FEATURE_ALTERNATIVE_CALCS === 'true';


    public goToManagerSettings (): void {
        this.$router.push('/manager-settings');
    }

    public goToBulkOperations (): void {
        this.$router.push('/bulk-operations');
    }

    get isManagerDataAvailable (): boolean {
        return this.updatedAltCalcData.length > 0;
    }

    public closeUnsavedPopUp (): void {
        this.$store.commit('updateShowUnSavedMessage', false);
    }

    public getManagerIds (): Array<number> {
        return this.updatedAltCalcData.map(x => x.entityNameId);;
    }

    public updateMoicForManager ([id, value]: [number, boolean]): void {

        const managerAltCalcData = this.updatedAltCalcData.find(x => x.entityNameId === id);
        if (managerAltCalcData) {
            Vue.set(managerAltCalcData, 'isMultipleOnInvestedCapitalCalculable', value);
        }
    }

    public updateIrrForManager ([id, value]: [number, boolean]): void {
        const managerAltCalcData = this.updatedAltCalcData.find(x => x.entityNameId === id);

        if (managerAltCalcData) {
            Vue.set(managerAltCalcData, 'isInternalRateOfReturnCalculable', value);
        }
    }

    public updateIrrDateForManager ([date, id]: [string | null, number]): void {
        const managerAltCalcData = this.updatedAltCalcData.find(x => x.entityNameId === id);

        if (managerAltCalcData) {
            Vue.set(managerAltCalcData, 'moicIrrCalculableStartDate', date);
        }
    }


    public selectedYear = new Date().getFullYear().toString();

    created (): void {
        this.getAllData();
    }


    public async getAllData (): Promise<void> {
        if (this.unsavedData) {
            this.$store.commit('updateShowUnSavedMessage', true);
            return;
        }

        this.isFetchingData = true;

        try {
            const [altCalcData, altCalcDefaultDate] = await Promise.all([
                altCalcsDataApi.getAltCalcData(),
                altCalcsDataApi.getAltCalcDefaultDate()
            ]);
            this.originalAltCalcData = altCalcData;
            this.updatedAltCalcData = cloneDeep(this.originalAltCalcData);
            this.defaultDateObjects = [altCalcDefaultDate];
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error fetching the data');
        } finally {
            this.isFetchingData = false;
        }

    }

    public async saveData (): Promise<void> {
        this.$store.commit('updateShowUnSavedMessage', false);
        this.$store.commit('updateUnsavedData', false);

        this.isFetchingData = true;
        try {
            if (this.altCalcDataToBeSaved.length > 0) {
                const response = await altCalcsDataApi.saveAltCalcData(this.altCalcDataToBeSaved);
                this.originalAltCalcData = response;
                this.updatedAltCalcData = cloneDeep(this.originalAltCalcData);
            }
        } catch (error) {
            this.getAllData();
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error saving the data');
        } finally {
            this.isFetchingData = false;
        }
    }

    public discardChanges (): void {
        this.$store.commit('updateUnsavedData', false);
        this.$store.commit('updateShowUnSavedMessage', false);
        this.getAllData();
    }

    get isDataToBeSaved (): boolean {
        if (this.altCalcDataToBeSaved.length > 0) {
            return true;
        }
        else {
            return false;
        }
    }

    @Watch('altCalcDataToBeSaved')
    private setUnsavedData (altCalcDataToBeSaved: Array<AlternativeCalculationSettings>) {
        if (!altCalcDataToBeSaved) return;
        this.$store.commit('updateUnsavedData', altCalcDataToBeSaved.length > 0);
    }

    get showUnSavedMessage (): boolean {
        return this.$store.state.showUnSavedMessage;
    }

    get unsavedData (): boolean {
        return this.$store.state.unsavedData;
    }

    get altCalcDataToBeSaved (): Array<AlternativeCalculationSettings> {
        const managersWithDataToBeSaved: Array<AlternativeCalculationSettings> = [];

        this.updatedAltCalcData.forEach((updatedData: AlternativeCalculationSettings) => {
            const originalData = this.originalAltCalcData.find(x => x.entityNameId === updatedData.entityNameId);

            if (
                updatedData.moicIrrCalculableStartDate !== originalData.moicIrrCalculableStartDate ||
            updatedData.isMultipleOnInvestedCapitalCalculable !== originalData.isMultipleOnInvestedCapitalCalculable ||
            updatedData.isInternalRateOfReturnCalculable !== originalData.isInternalRateOfReturnCalculable
            ) {
                managersWithDataToBeSaved.push(updatedData);
            }
        });

        return managersWithDataToBeSaved;
    }


}
