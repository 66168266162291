
















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import { FORMAT_DATE_UI, FORMAT_DATE_ISO_SHORT } from '@/constants';

@Component({
    components: {
        DatePicker,
    },
})
export default class DatePickerForAltCalcs extends Vue {
    @Prop({type: String, required: false}) selectedDate!: string;

    @Prop({type: Boolean, required: false}) disabled!: boolean;

    format = FORMAT_DATE_UI;

    valueType = FORMAT_DATE_ISO_SHORT;

    public DATE_TODAY = dayjs().format(FORMAT_DATE_ISO_SHORT);

    get localDate (): string {
        return this.selectedDate ? dayjs(this.selectedDate, FORMAT_DATE_UI).format(this.valueType) : '';
    }

    isAfterToday (date: Date): boolean {
        return dayjs(date).endOf('day').isAfter(dayjs());
    }

    @Emit('updateDate')
    updateDate (date: string | null): string | null {
        if (date === null || date === '') {
            return null;
        }
        return dayjs(date).format(FORMAT_DATE_ISO_SHORT);
    }
}
