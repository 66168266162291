var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "manager--content",
      class: { centerY: !_vm.isManagerDataAvailable },
    },
    [
      _c(
        "div",
        {
          staticClass: "performance",
          class: { "no-data": !_vm.isManagerDataAvailable },
        },
        [
          _vm.showUnSavedMessage
            ? _c("BaseModal", {
                class: { "show-modal": _vm.showUnSavedMessage },
                on: { close: _vm.closeUnsavedPopUp },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("UnSavedMessage", {
                            on: {
                              cancelMove: _vm.closeUnsavedPopUp,
                              saveChanges: _vm.saveData,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  721083903
                ),
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "performance-wrapper" },
            [
              _vm.isFetchingData ? _c("LoadingOverlay") : _vm._e(),
              _vm.isManagerDataAvailable
                ? _c("div", [
                    _c("div", { staticClass: "table-wrapper flex" }, [
                      _c(
                        "div",
                        { staticClass: "table-inputs-wrapper" },
                        [
                          _c("AlternativeCalculationSettingsTable", {
                            ref: "AlternativeCalculationSettingsTableRef",
                            attrs: {
                              managerIds: _vm.managerIds,
                              originalSettings: _vm.originalAltCalcData,
                              updatedSettings: _vm.updatedAltCalcData,
                              defaultDateObjects: _vm.defaultDateObjects,
                            },
                            on: {
                              updateMoicForManager: _vm.updateMoicForManager,
                              updateIrrForManager: _vm.updateIrrForManager,
                              updateIrrDateForManager:
                                _vm.updateIrrDateForManager,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "save-slide-out",
                        class: { "show-save": _vm.isDataToBeSaved },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "slide-out-inner flex flex--row-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "btn btn--discard",
                                on: { click: _vm.discardChanges },
                              },
                              [_vm._v("Discard")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "btn btn--default",
                                on: { click: _vm.saveData },
                              },
                              [_vm._v("Save")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _c("div", { staticClass: "no-manager-data flex" }, [
                    _c("div", [
                      _c("p", { staticClass: "message-text" }, [
                        _vm._v(
                          "You currently have no investments in your setup. Please upload a file or update Manager Settings"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex flex--row-space-around mt-3" },
                        [
                          _c(
                            "div",
                            { staticClass: "flex flex--column column-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "theme-icon flex flex--row-center",
                                  attrs: { title: "Manager Settings" },
                                  on: { click: _vm.goToManagerSettings },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "user-cog"] },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" Manager Settings "),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "flex flex--column column-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "theme-icon flex flex--row-center",
                                  attrs: { title: "Upload excel file" },
                                  on: { click: _vm.goToBulkOperations },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["fas", "file-excel"] },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" Upload file "),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }